
/* RULES */

.coming-soon{
    max-width: 100vw;
    overflow-wrap: break-word;  
}

.under-reconstruction{
    font-size: 40px;
    text-shadow:    
        5px 5px 40px var(--bootstrap-danger),
        -5px 5px 40px var(--bootstrap-danger),
        -5px -5px 40px var(--bootstrap-danger),
        5px -5px 40px var(--bootstrap-danger)
    ;
    overflow-wrap: break-word;
}

.coming-soon-msg{
    font-size: 26px;
    text-shadow:    
        2px 2px 10px white,
        -2px 2px 10px white,
        -2px -2px 10px white,
        2px -2px 10px white
    ;
}


/* CONDITIONAL FORMATTING */

@media screen and (max-width: 400px) {
    .under-reconstruction{
        font-size: 33px;
    }

    .coming-soon-msg{
        font-size: 22px;
    }
}