
/* VARS */
:root{
    --bootstrap-danger: rgb(220, 54, 69);
}



/* RULES */

body{
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

main{
    background-image: url('./assets/noah-with-saxophone.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 42%;
}